/*
 * Fonts
 *
 * @author Christophe Meade
 * @copyright 2019-present Christophe Meade
 */

import FontFaceObserver from 'fontfaceobserver';
import addClass from '@sparkle/js/fn/attributes/addClass';
import select from '@sparkle/js/fn/select/select';

// Fonts
const observers = [];
observers.push(new FontFaceObserver('matter').load(null, 5000));
observers.push(new FontFaceObserver('sora').load(null, 5000));

Promise.all(observers)
    .then(() => {
        addClass(select('html'), 'fonts-loaded');
    })
    .catch(() => {
        addClass(select('html'), 'fonts-loaded');
    });
